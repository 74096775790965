import styled from "styled-components";

export const EmailTemplateContainer = styled.div`
    background-color: white;
    text-align: center;

    img{
        width: 120px;
        height: auto;
        
    }
   

`