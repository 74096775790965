import styled from "styled-components";

export const CustomInputContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;

  label {
    font-size: 13px;
    text-transform: capitalize;
    ////sad
    display: block;
    margin-bottom: 10px;
    color:#1B283F;
  }
  .ant-input {
    padding: 8px 8px !important;
}
`;
