import styled from "styled-components";

export const CustomTextAreaContaienr = styled.div`
 margin-bottom: 10px;

label{
    font-size: 13px;
    text-transform: capitalize;
    display: block;
    margin-bottom: 10px;
}
.customInput.ant-input{
    background: white;
    border: 1px solid #C6C6C8;
    border-radius: 5px;
    padding: 10px;
    height: 16vh;
}
`