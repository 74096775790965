import styled from 'styled-components';

export const NavbarStyledMain=styled.div`
.navbar{
    background-color: #156985;
.nav-link{
color:#FFFFFF;
}    
}
.navbar-light .navbar-toggler{
border-color:unset;
/* border: none; */
}
.navbar-light .navbar-toggler:focus{
box-shadow: none;
}
.navbar-brand{
 font-size:16px;
 color:#FFFFFF;
}

`